import type { OptionalQuery as OptionalQuery_1gzkap7 } from '../app/[companyId]/(admin)/approval-request/page';
import type { OptionalQuery as OptionalQuery_rvq9t9 } from '../app/[companyId]/(admin)/approval-request/@sheet/(group)/[userId]/[applicationId]/page';
import type { OptionalQuery as OptionalQuery_1ve24ol } from '../app/[companyId]/(admin)/data-export/page';
import type { OptionalQuery as OptionalQuery_ynzgw } from '../app/[companyId]/(admin)/joining-company-applications/page';
import type { OptionalQuery as OptionalQuery_1felp28 } from '../app/[companyId]/(admin)/member-management/page';
import type { OptionalQuery as OptionalQuery_95fkv1 } from '../app/[companyId]/(admin)/member-management/[uid]/page';
import type { OptionalQuery as OptionalQuery_wl9br6 } from '../app/[companyId]/(admin)/overtime-status/page';
import type { OptionalQuery as OptionalQuery_fa2don } from '../app/[companyId]/(admin)/overtime-status/[userId]/page';
import type { OptionalQuery as OptionalQuery_1jyh64k } from '../app/[companyId]/(admin)/work-status/page';
import type { OptionalQuery as OptionalQuery_15vkpyi } from '../app/[companyId]/(admin)/work-status/@sheet/(group)/[userId]/page';
import type { OptionalQuery as OptionalQuery_83hys1 } from '../app/[companyId]/history/page';

const buildSuffix = (url?: {query?: Record<string, string>, hash?: string}) => {
  const query = url?.query;
  const hash = url?.hash;
  if (!query && !hash) return '';
  const search = query ? `?${new URLSearchParams(query)}` : '';
  return `${search}${hash ? `#${hash}` : ''}`;
};

export const pagesPath = {
  _companyId: (companyId: string | number) => ({
    "admin_settings": {
      "shared_attendance": {
        $url: (url?: { hash?: string }) => ({ pathname: '/[companyId]/admin-settings/shared-attendance' as const, query: { companyId }, hash: url?.hash, path: `/${companyId}/admin-settings/shared-attendance${buildSuffix(url)}` })
      },
      $url: (url?: { hash?: string }) => ({ pathname: '/[companyId]/admin-settings' as const, query: { companyId }, hash: url?.hash, path: `/${companyId}/admin-settings${buildSuffix(url)}` })
    },
    "approval_request": {
      "@sheet": {
        _userId: (userId: string | number) => ({
          _applicationId: (applicationId: string | number) => ({
            $url: (url?: { query?: OptionalQuery_rvq9t9, hash?: string }) => ({ pathname: '/[companyId]/approval-request/@sheet/[userId]/[applicationId]' as const, query: { companyId, userId, applicationId, ...url?.query }, hash: url?.hash, path: `/${companyId}/approval-request/@sheet/${userId}/${applicationId}${buildSuffix(url)}` })
          })
        }),
        $url: (url?: { hash?: string }) => ({ pathname: '/[companyId]/approval-request/@sheet' as const, query: { companyId }, hash: url?.hash, path: `/${companyId}/approval-request/@sheet${buildSuffix(url)}` })
      },
      $url: (url?: { query?: OptionalQuery_1gzkap7, hash?: string }) => ({ pathname: '/[companyId]/approval-request' as const, query: { companyId, ...url?.query }, hash: url?.hash, path: `/${companyId}/approval-request${buildSuffix(url)}` })
    },
    "data_export": {
      "select_member": {
        $url: (url?: { hash?: string }) => ({ pathname: '/[companyId]/data-export/select-member' as const, query: { companyId }, hash: url?.hash, path: `/${companyId}/data-export/select-member${buildSuffix(url)}` })
      },
      $url: (url?: { query?: OptionalQuery_1ve24ol, hash?: string }) => ({ pathname: '/[companyId]/data-export' as const, query: { companyId, ...url?.query }, hash: url?.hash, path: `/${companyId}/data-export${buildSuffix(url)}` })
    },
    "joining_company_applications": {
      $url: (url?: { query?: OptionalQuery_ynzgw, hash?: string }) => ({ pathname: '/[companyId]/joining-company-applications' as const, query: { companyId, ...url?.query }, hash: url?.hash, path: `/${companyId}/joining-company-applications${buildSuffix(url)}` })
    },
    "member_management": {
      _uid: (uid: string | number) => ({
        $url: (url?: { query?: OptionalQuery_95fkv1, hash?: string }) => ({ pathname: '/[companyId]/member-management/[uid]' as const, query: { companyId, uid, ...url?.query }, hash: url?.hash, path: `/${companyId}/member-management/${uid}${buildSuffix(url)}` })
      }),
      $url: (url?: { query?: OptionalQuery_1felp28, hash?: string }) => ({ pathname: '/[companyId]/member-management' as const, query: { companyId, ...url?.query }, hash: url?.hash, path: `/${companyId}/member-management${buildSuffix(url)}` })
    },
    "overtime_status": {
      _userId: (userId: string | number) => ({
        $url: (url?: { query?: OptionalQuery_fa2don, hash?: string }) => ({ pathname: '/[companyId]/overtime-status/[userId]' as const, query: { companyId, userId, ...url?.query }, hash: url?.hash, path: `/${companyId}/overtime-status/${userId}${buildSuffix(url)}` })
      }),
      $url: (url?: { query?: OptionalQuery_wl9br6, hash?: string }) => ({ pathname: '/[companyId]/overtime-status' as const, query: { companyId, ...url?.query }, hash: url?.hash, path: `/${companyId}/overtime-status${buildSuffix(url)}` })
    },
    "paid_leave": {
      _userId: (userId: string | number) => ({
        $url: (url?: { hash?: string }) => ({ pathname: '/[companyId]/paid-leave/[userId]' as const, query: { companyId, userId }, hash: url?.hash, path: `/${companyId}/paid-leave/${userId}${buildSuffix(url)}` })
      }),
      $url: (url?: { hash?: string }) => ({ pathname: '/[companyId]/paid-leave' as const, query: { companyId }, hash: url?.hash, path: `/${companyId}/paid-leave${buildSuffix(url)}` })
    },
    "work_status": {
      "@changelog": {
        "change_log": {
          $url: (url?: { hash?: string }) => ({ pathname: '/[companyId]/work-status/@changelog/change_log' as const, query: { companyId }, hash: url?.hash, path: `/${companyId}/work-status/@changelog/change_log${buildSuffix(url)}` })
        },
      },
      "@sheet": {
        _userId: (userId: string | number) => ({
          $url: (url?: { query?: OptionalQuery_15vkpyi, hash?: string }) => ({ pathname: '/[companyId]/work-status/@sheet/[userId]' as const, query: { companyId, userId, ...url?.query }, hash: url?.hash, path: `/${companyId}/work-status/@sheet/${userId}${buildSuffix(url)}` })
        }),
      },
      $url: (url?: { query?: OptionalQuery_1jyh64k, hash?: string }) => ({ pathname: '/[companyId]/work-status' as const, query: { companyId, ...url?.query }, hash: url?.hash, path: `/${companyId}/work-status${buildSuffix(url)}` })
    },
    "history": {
      $url: (url?: { query?: OptionalQuery_83hys1, hash?: string }) => ({ pathname: '/[companyId]/history' as const, query: { companyId, ...url?.query }, hash: url?.hash, path: `/${companyId}/history${buildSuffix(url)}` })
    },
    "my_page": {
      $url: (url?: { hash?: string }) => ({ pathname: '/[companyId]/my-page' as const, query: { companyId }, hash: url?.hash, path: `/${companyId}/my-page${buildSuffix(url)}` })
    },
    $url: (url?: { hash?: string }) => ({ pathname: '/[companyId]' as const, query: { companyId }, hash: url?.hash, path: `/${companyId}${buildSuffix(url)}` })
  }),
  "auth": {
    "@otp": {
      $url: (url?: { hash?: string }) => ({ pathname: '/auth/@otp' as const, hash: url?.hash, path: `/auth/@otp${buildSuffix(url)}` })
    },
    $url: (url?: { hash?: string }) => ({ pathname: '/auth' as const, hash: url?.hash, path: `/auth${buildSuffix(url)}` })
  },
  "join": {
    $url: (url?: { hash?: string }) => ({ pathname: '/join' as const, hash: url?.hash, path: `/join${buildSuffix(url)}` })
  },
  "suspended": {
    $url: (url?: { hash?: string }) => ({ pathname: '/suspended' as const, hash: url?.hash, path: `/suspended${buildSuffix(url)}` })
  }
};

export type PagesPath = typeof pagesPath;

export const staticPath = {
  $404_png: '/404.png',
  assets: {
    time_stamp_explanation_png: '/assets/time-stamp-explanation.png'
  },
  auth_service_worker_js: '/auth-service-worker.js',
  company_placeholder_svg: '/company-placeholder.svg',
  logo_and_horizontal_text_svg: '/logo-and-horizontal-text.svg',
  logo_svg: '/logo.svg',
  mascot_suspended_svg: '/mascot-suspended.svg',
  moon_filled_svg: '/moon-filled.svg',
  next_svg: '/next.svg',
  nightlight_svg: '/nightlight.svg',
  sunny_filled_svg: '/sunny-filled.svg',
  sunny_svg: '/sunny.svg',
  user_avatar_icons: {
    alligator_map_svg: '/user_avatar_icons/alligator-map.svg',
    alligator_svg: '/user_avatar_icons/alligator.svg',
    alpaca_map_svg: '/user_avatar_icons/alpaca-map.svg',
    alpaca_svg: '/user_avatar_icons/alpaca.svg',
    banana_map_svg: '/user_avatar_icons/banana-map.svg',
    banana_svg: '/user_avatar_icons/banana.svg',
    cactus_map_svg: '/user_avatar_icons/cactus-map.svg',
    cactus_svg: '/user_avatar_icons/cactus.svg',
    camel_map_svg: '/user_avatar_icons/camel-map.svg',
    camel_svg: '/user_avatar_icons/camel.svg',
    cat_map_svg: '/user_avatar_icons/cat-map.svg',
    cat_svg: '/user_avatar_icons/cat.svg',
    cherry_blossom_map_svg: '/user_avatar_icons/cherry-blossom-map.svg',
    cherry_blossom_svg: '/user_avatar_icons/cherry-blossom.svg',
    dinasour_map_svg: '/user_avatar_icons/dinasour-map.svg',
    dinosaur_svg: '/user_avatar_icons/dinosaur.svg',
    elephant_map_svg: '/user_avatar_icons/elephant-map.svg',
    elephant_svg: '/user_avatar_icons/elephant.svg',
    frog_map_svg: '/user_avatar_icons/frog-map.svg',
    frog_svg: '/user_avatar_icons/frog.svg',
    gorilla_map_svg: '/user_avatar_icons/gorilla-map.svg',
    gorilla_svg: '/user_avatar_icons/gorilla.svg',
    koala_map_svg: '/user_avatar_icons/koala-map.svg',
    koala_svg: '/user_avatar_icons/koala.svg',
    monkey_map_svg: '/user_avatar_icons/monkey-map.svg',
    monkey_svg: '/user_avatar_icons/monkey.svg',
    mushroom_map_svg: '/user_avatar_icons/mushroom-map.svg',
    mushroom_svg: '/user_avatar_icons/mushroom.svg',
    octopus_map_svg: '/user_avatar_icons/octopus-map.svg',
    octopus_svg: '/user_avatar_icons/octopus.svg',
    palm_tree_map_svg: '/user_avatar_icons/palm-tree-map.svg',
    palm_tree_svg: '/user_avatar_icons/palm-tree.svg',
    penguin_map_svg: '/user_avatar_icons/penguin-map.svg',
    penguin_svg: '/user_avatar_icons/penguin.svg',
    person_map_svg: '/user_avatar_icons/person-map.svg',
    person_svg: '/user_avatar_icons/person.svg',
    polar_bear_map_svg: '/user_avatar_icons/polar-bear-map.svg',
    polar_bear_svg: '/user_avatar_icons/polar-bear.svg',
    reindeer_map_svg: '/user_avatar_icons/reindeer-map.svg',
    reindeer_svg: '/user_avatar_icons/reindeer.svg',
    shrimp_map_svg: '/user_avatar_icons/shrimp-map.svg',
    shrimp_svg: '/user_avatar_icons/shrimp.svg',
    slug_map_svg: '/user_avatar_icons/slug-map.svg',
    slug_svg: '/user_avatar_icons/slug.svg',
    tiger_map_svg: '/user_avatar_icons/tiger-map.svg',
    tiger_svg: '/user_avatar_icons/tiger.svg',
    tuna_map_svg: '/user_avatar_icons/tuna-map.svg',
    tuna_svg: '/user_avatar_icons/tuna.svg'
  },
  vercel_svg: '/vercel.svg'
} as const;

export type StaticPath = typeof staticPath;
